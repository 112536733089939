<template>
  <div class="index" >
    <van-sticky>
    <div @click="searchBtn" ref="header" style="border-bottom: 1px #F6F6F6 solid;">
      <van-search shape="round" :readonly="true" placeholder="输入医学公式关键词进行搜索" />
    </div>
    </van-sticky>
    <div class="list"  v-if="type.length!==0">
      <div class="left custom-scroll" >
        <div
            v-for="(item, index) in type"
            :key="index"
            :class="{'item': true, 'selected': selectedIndex === index, 'unselected': selectedIndex !== index}"
            @click="selectItem(index,item)"
        >
          {{ item.title }}({{item.count}})
        </div>
      </div>

      <div class="right custom-scroll" ref="rightContainer">
        <van-index-bar  :sticky="false" :index-list="indexList" ref="indexBar" >
          <div v-for="(item,index) in list" :key="index">
            <van-index-anchor  :index="item.pinyin" />
            <van-cell style="margin-top: -5px" v-for="(row) in item.formulas" :key="row.code"  @click="routeBtn(row,item.pinyin)">
              <template #title >
                <div class="custom-title">{{ row.chineseName}}</div>
                <div class="sub-title">{{row.englishName }}</div>
                <div  style="border-bottom: 1px #F6F6F6 solid;margin:8px 0 -2px 0"></div>
              </template>
            </van-cell>
          </div>
        </van-index-bar>

      </div>
    </div>

    <van-empty v-else description="暂无内容" />

  </div>
</template>


<script>
import {getMedicalType, getMedicalType_list} from "@/service/api";
import {postDataBrowse} from "@/service/api";
import { getAdmins } from "@/utils/adminDate";
export default {
  name: "medicalType",
  data(){
    return{
      searchValue:'',
      minHeight: window.innerHeight + 'px',
      indexList: [],
      selectedIndex: this.$store.state.medicalMenu_typeIndex,
      type:[],
      list:[],
    }
  },
  activated() {
    const index = this.$store.state.medicalMenu_typeListIndex;
    if (index) {
      this.$nextTick(() => {
        if (this.$refs.indexBar) {
          this.$refs.indexBar.scrollTo(index);
        }
      });
    }
  },
  mounted() {
    this.getType()

  },
  destroyed() {
    // this.$store.commit("medicalMenu_typeListIndex",0)
  },
  methods: {
    async getType(){
      const res = await  getMedicalType()
      if(res.statuscode===200){
        this.type=res.data
        this.getList()
      }
    },
    async getList(){
      const res = await  getMedicalType_list(this.type[this.selectedIndex].code)
      if(res.statuscode===200){
        this.indexList=[]

        res.data.forEach(value=>{
          this.indexList.push(value.pinyin)
        })
        this.list=res.data
      }
    },

    selectItem(index) {
      this.$store.commit("medicalMenu_typeIndex",index)
      this.$refs.rightContainer.scrollTop = 0;
      this.$store.commit("medicalMenu_typeListIndex",0)
      this.selectedIndex = index;
      this.getList()
    },
    searchBtn(){
      this.$store.commit("editForm",{
        type:'YXGS_CLASS',
        memberId:this.$route.query.memberId,
        channel:this.$route.query.channel,
      })
      this.$router.push({
        name:"medicalSearch",
        params:{}
      })
    },
    routeBtn(item,index){
      this.$store.commit("medicalMenu_typeListIndex",index)
      this.$router.push({
        path:'/formula/'+item.code,
        query:{
          id:item.id,
          memberId:this.$route.query.memberId,
          channel:this.$route.query.channel,
          type:'h5',
          location:'YXGS_CLASS_COLUMN',
        }
      });
      this.addClickRecord(item,index);
    },
     async addClickRecord(item) {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel
      );
      //点击埋点
      const data = {
        type: 'h5', //index表示在首页列表中点击，mix_index表示在专题列表中点击
        contentId: item.id, //为vod时，根据contentId增加浏览量
        contentType: "YXGS_DWHS",
        location: 'YXGS_CLASS_COLUMN',
        channel: this.$route.query.channel,
        os: arrays.os,
        memberId: this.$route.query.memberId,
      };
      console.log(data);
      await postDataBrowse(data)
    },
  }
}
</script>



<style scoped lang="scss">
::v-deep .van-index-anchor{
  color: #999999;
  padding-top: 3px;
  background: white!important;
}
.custom-title{
  font-size: 16px;
  color: #333333;
  display:-webkit-box;
  /* 表示几行后超出隐藏 */
  -webkit-line-clamp:2;
  /* 超出隐藏 */
  overflow:hidden;
  /*超出显示为省略号*/
  text-overflow:ellipsis;
  -webkit-box-orient:vertical;
  /*强制英文单词自动换行*/
  word-break:break-all;
}
.sub-title{
  display:-webkit-box;
  /* 表示几行后超出隐藏 */
  -webkit-line-clamp:1;
  /* 超出隐藏 */
  overflow:hidden;
  /*超出显示为省略号*/
  text-overflow:ellipsis;
  -webkit-box-orient:vertical;
  /*强制英文单词自动换行*/
  word-break:break-all;
  margin-top: 5px;
  color: #999999;
  font-size: 13px;
}
.index{
  width:100%;
  background: #F6F6F6;
  height: 100vh;
  max-height: 100vh;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  flex-direction: column;
}
.list{

  overflow: hidden;
  //margin-top: 50px;
  //position:fixed;
  //padding-bottom: 40px;

  //bottom:50px;
  //left:0;
  //right:0;
  display: flex;

  height: calc(100vh - 50px);
  background-color: white;
  max-height: 100vh;
  ::v-deep .van-cell::after{
    border: none;
  }
}
.item {
  padding: 20px;
  cursor: pointer;
}
.unselected {
  background-color: #F6F6F6;
  color: #999999;
}
.selected {
  background-color: white;
}
.left{
  width: 40%;
  padding-bottom: 100px;
  overflow-y:auto;
  height: 80vh;
  background-color: #F6F6F6;
}
.right{
  background: white;
  padding-bottom: 100px;
  overflow-y:auto;
  width:60%;
  height: 80vh;
}
.custom-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.custom-scroll::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}
</style>